export function Footer() {
  return (
    <div
      className="flex justify-center align-center p-4 mt-12"
      style={{ backgroundColor: "#f3ebf7" }}
    >
      <p className="text-md">© AMS Monitoring Simplified</p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

const LocationList = () => {
  const [form] = Form.useForm();
  const [locationData, setLocationData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(0);
  const [actionForm, setActionForm] = useState("");
  const [locationId, setLocationId] = useState(0);
  const [listCompany, setListCompany] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [locationFilter, setLocationFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showModal = (action = "create") => {
    if (action === "create") {
      form.resetFields();
    }
    const roleId = parseInt(window.localStorage.getItem("roleId"));
    const companyId = window.localStorage.getItem("companyId");
    let url;
    if (roleId !== 1) {
      url = `${process.env.REACT_APP_API_URL}/company?id=${companyId}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/company`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newListCompany = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newListCompany.push({
            value: element.id,
            label: `${element.code}-${element.name}`,
          });
        }
        setListCompany(newListCompany);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setActionForm("update");
              setLocationId(record.id);
              axios
                .get(`${process.env.REACT_APP_API_URL}/location/${record.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  showModal();
                  form.setFieldsValue(response.data.data);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    window.location.href = "/login";
                  }
                });
            }}
          >
            Edit
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/location/${record.id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      setFlag((prev) => prev + 1);
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.response.data.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Delete
          </Button>
          <Link to={`add-plotting/${record.id}`}>
            <Button type="primary">Add Plotting</Button>
          </Link>
        </Space>
      ),
    },
  ];

  const token = window.localStorage.getItem("token");
  useEffect(() => {
    fetchRecords(1, 10);
  }, [flag]);

  const fetchRecords = (page, pageSize, locationFilter = "") => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/location?page=${page}&per_page=${pageSize}&name=${locationFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLocationData(response.data.data);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    if (actionForm === "create") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/location`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.response.data.message,
            });
          }
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/location/${locationId}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.response.data.message,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setIsLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onChangeCompany = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearchCompany = (value) => {
    console.log("search:", value);
  };

  const filterOptionCompany = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Location List</p>
        <Button
          type="primary"
          onClick={() => {
            showModal("create");
            setActionForm("create");
          }}
        >
          Create Location
        </Button>
      </div>
      <div className="mx-16 mt-16">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Location Name"
            style={{ width: "50%" }}
            onChange={(e) => {
              setLocationFilter(e.target.value);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "left" }}
              onClick={() => {
                fetchRecords(1, 10, locationFilter);
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={locationData}
          pagination={{
            total: totalListData,
            onChange: (page, pageSize) => {
              fetchRecords(page, pageSize, locationFilter);
            },
          }}
        />
      </div>
      <Modal
        title="Location Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          isLoading ? <Spin key="loading" /> : null,
          !isLoading ? (
            <Button form="formLocationList" key="submit" htmlType="submit">
              Submit
            </Button>
          ) : null,
        ]}
      >
        <Form
          form={form}
          name="formLocationList"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Location Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your Location Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Latitude"
            name="latitude"
            rules={[
              {
                required: true,
                message: "Please input your Latitude!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Longitude"
            name="longitude"
            rules={[
              {
                required: true,
                message: "Please input your Longitude!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Perusahaan"
            name="company_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu perusahaan"
              optionFilterProp="children"
              onChange={onChangeCompany}
              onSearch={onSearchCompany}
              filterOption={filterOptionCompany}
              options={listCompany}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LocationList;

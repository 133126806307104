import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  Form,
  message,
  Select,
  InputNumber,
  TimePicker,
  DatePicker,
  Spin,
  Input,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import moment from "moment-timezone";

const ScheduleList = () => {
  dayjs.extend(customParseFormat);
  const [form] = Form.useForm();
  const [ListData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionForm, setActionForm] = useState("");
  const [flag, setFlag] = useState(0);
  const [employeeList, setEmployeeList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [plotList, setPlotList] = useState([]);
  const [subPlotList, setSubPlotList] = useState([]);
  const [scheduleId, setScheduleId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nikFilter, setNikFilter] = useState("");

  const listWorkDay = [
    {
      label: "Senin",
      value: 0,
    },
    {
      label: "Selasa",
      value: 1,
    },
    {
      label: "Rabu",
      value: 2,
    },
    {
      label: "Kamis",
      value: 3,
    },
    {
      label: "Jumat",
      value: 4,
    },
    {
      label: "Sabtu",
      value: 5,
    },
    {
      label: "Minggu",
      value: 6,
    },
  ];

  const columns = [
    {
      title: "Nama Karyawan",
      dataIndex: "user.name",
      key: "user.name",
      render: (_, record) => {
        return record?.user?.name;
      },
    },
    {
      title: "Lokasi",
      dataIndex: "location.name",
      key: "location.name",
      render: (_, record) => {
        return record?.location?.name;
      },
    },
    {
      title: "Plot",
      dataIndex: "plot.name",
      key: "plot.name",
      render: (_, record) => {
        return record?.plot?.name;
      },
    },
    {
      title: "Sub Plot",
      dataIndex: "sub_plot.name",
      key: "sub_plot.name",
      render: (_, record) => {
        return record?.sub_plot?.name;
      },
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setActionForm("update");
              setScheduleId(record.id);
              axios
                .get(`${process.env.REACT_APP_API_URL}/schedule/${record.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  showModal();
                  let detail = response.data.data;
                  detail.start_time = dayjs(detail.start_time, "HH:mm:ss");
                  detail.end_time = dayjs(detail.end_time, "HH:mm:ss");
                  detail.periode = dayjs(detail.periode);
                  const workday = JSON.parse(detail.workday);
                  let newWorkDay = [];
                  workday.sort((a, b) => a.id - b.id);
                  workday.forEach((item, index) => {
                    newWorkDay.push(parseInt(item.id));
                  });
                  getPlotByLocation(detail.location_id);
                  getSubPlotByPlot(detail.plotting_id);
                  detail.workday = newWorkDay;
                  form.setFieldsValue(detail);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    window.location.href = "/login";
                  }
                });
            }}
          >
            Edit
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/schedule/${record.id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      setFlag((prev) => prev + 1);
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const token = window.localStorage.getItem("token");

  useEffect(() => {
    fetchRecords(1, 10);
  }, [flag]);

  const fetchRecords = (page, pageSize, nikFilter = "") => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/schedule?page=${page}&per_page=${pageSize}&nik=${nikFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListData(response.data.data);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const showModal = (action = "create") => {
    if (action === "create") {
      form.resetFields();
    }
    getEmployeeList();
    getLocationList();
    setIsModalOpen(true);
  };

  const getEmployeeList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newEmployeeList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newEmployeeList.push({
            value: element.id,
            label: `${element.nik} | ${element.name}`,
          });
        }
        setEmployeeList(newEmployeeList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const getLocationList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/location`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newLocationList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newLocationList.push({
            value: element.id,
            label: element.name,
          });
        }
        setLocationList(newLocationList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    setIsLoading(true);
    if (values.shift === 2) {
      values.start_time = "15:00:00";
      values.end_time = "22:00:00";
    } else {
      values.start_time = "07:00:00";
      values.end_time = "15:00:00";
    }
    // values.start_time = moment(new Date(values.start_time)).format("HH:mm:ss");
    // values.end_time = moment(new Date(values.end_time)).format("HH:mm:ss");
    values.periode = moment(new Date(values.periode)).format("YYYY-MM");
    const workday = values.workday.reverse();
    let newWorkDate = [];
    for (let index = 0; index < workday.length; index++) {
      const element = workday[index];
      const findWorkDay = listWorkDay.find((item) => {
        return item.value === element;
      });
      newWorkDate.push({
        id: findWorkDay.value,
        name: findWorkDay.label,
        checked: true,
      });
    }
    values.workday = newWorkDate;
    // console.log("Success:", values, actionForm);
    if (actionForm === "create") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/schedule`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/schedule/${scheduleId}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const filterOptionEmployee = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterOptionLocation = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterOptionPlot = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeLocation = (value) => {
    getPlotByLocation(value);
  };

  const getPlotByLocation = (locationId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/plotting?location_id=${locationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let newPlotList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newPlotList.push({
            value: element.id,
            label: element.name,
          });
        }
        setPlotList(newPlotList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onChangePlot = (value) => {
    getSubPlotByPlot(value);
  };

  const getSubPlotByPlot = (plotId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subplotting?plot_id=${plotId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newSubPlotList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newSubPlotList.push({
            value: element.id,
            label: element.name,
          });
        }
        setSubPlotList(newSubPlotList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const filterOptionSubPlot = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Data Jadwal</p>
        <div>
          <Button
            type="primary"
            onClick={() => {
              axios
                .get(`${process.env.REACT_APP_API_URL}/schedule/export`, {
                  responseType: "blob",
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  const href = URL.createObjectURL(response.data);
                  const link = document.createElement("a");
                  link.href = href;
                  link.setAttribute("download", "schedule.xlsx"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
                });
            }}
            style={{ marginRight: "10px" }}
          >
            Export
          </Button>
          <Button
            type="primary"
            onClick={() => {
              showModal("create");
              setActionForm("create");
            }}
          >
            Buat Jadwal
          </Button>
        </div>
      </div>
      <div className="mx-16 mt-16">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="nik"
            label="NIK"
            style={{ width: "50%" }}
            onChange={(e) => {
              setNikFilter(e.target.value);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "left" }}
              onClick={() => {
                fetchRecords(1, 10, nikFilter);
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={ListData}
          pagination={{
            total: totalListData,
            onChange: (page, pageSize) => {
              fetchRecords(page, pageSize, nikFilter);
            },
          }}
        />
      </div>
      <Modal
        title="Form Jadwal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          isLoading ? <Spin key="loading" /> : null,
          !isLoading ? (
            <Button form="formSchedule" key="submit" htmlType="submit">
              Submit
            </Button>
          ) : null,
        ]}
      >
        <Form
          form={form}
          name="formSchedule"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Karyawan"
            name="user_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu karyawan"
              optionFilterProp="children"
              filterOption={filterOptionEmployee}
              options={employeeList}
            />
          </Form.Item>

          <Form.Item
            label="Lokasi"
            name="location_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu lokasi"
              optionFilterProp="children"
              onChange={onChangeLocation}
              // onSearch={onSearchCompany}
              filterOption={filterOptionLocation}
              options={locationList}
            />
          </Form.Item>

          <Form.Item
            label="Plot"
            name="plotting_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu plot"
              optionFilterProp="children"
              onChange={onChangePlot}
              filterOption={filterOptionPlot}
              options={plotList}
            />
          </Form.Item>

          <Form.Item
            label="Sub Plot"
            name="sub_plotting_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu sub plot"
              optionFilterProp="children"
              filterOption={filterOptionSubPlot}
              options={subPlotList}
            />
          </Form.Item>

          <Form.Item
            label="Shift"
            name="shift"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu jadwal"
              optionFilterProp="children"
              filterOption={filterOptionPlot}
              options={[
                { value: 1, label: "Shift 1 (07.00 - 15.00)" },
                { value: 2, label: "Shift 2 (15.00 - 22.00)" },
              ]}
            />
          </Form.Item>

          {/* <Form.Item
            label="Jam Mulai"
            name="start_time"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <TimePicker
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Jam Selesai"
            name="end_time"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <TimePicker
              style={{
                width: "100%",
              }}
            />
          </Form.Item> */}

          <Form.Item
            label="Periode"
            name="periode"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
              picker="month"
            />
          </Form.Item>

          <Form.Item
            label="Hari Kerja"
            name="workday"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              placeholder="pilih hari kerja"
              options={listWorkDay}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ScheduleList;

import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import axios from "axios";

const EmployeeList = () => {
  const [form] = Form.useForm();
  const [ListData, setListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(0);
  const [actionForm, setActionForm] = useState("");
  const [DetailId, setDetailId] = useState(0);
  const [listRole, setListRole] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [listPosition, setListPosition] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [companyFilter, setCompanyFilter] = useState("");
  const [nikFilter, setNikFilter] = useState("");
  const [locationList, setLocationList] = useState([]);

  const showModal = (action = "create") => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/get-roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newListRole = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newListRole.push({
            value: element.id,
            label: element.name,
          });
        }
        setListRole(newListRole);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/company`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newListCompany = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newListCompany.push({
            value: element.id,
            label: `${element.code}-${element.name}`,
          });
        }
        setListCompany(newListCompany);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/users/get-position`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newListPosition = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newListPosition.push({
            value: element.id,
            label: `${element.title}`,
          });
        }
        setListPosition(newListPosition);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });

    if (action === "create") {
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "NIK",
      dataIndex: "nik",
      key: "nik",
    },
    {
      title: "Nama Karyawan",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Jabatan",
      dataIndex: "position.title",
      key: "position.title",
      render: (_, record) => {
        return record.position.title;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setActionForm("update");
              setDetailId(record.id);
              axios
                .get(`${process.env.REACT_APP_API_URL}/users/${record.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  showModal();
                  form.setFieldsValue(response.data.data);
                  getLocationList(response.data.data.company_id);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    window.location.href = "/login";
                  }
                });
            }}
          >
            Ubah
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/users/${record.id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      setFlag((prev) => prev + 1);
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const token = window.localStorage.getItem("token");
  const roleId = window.localStorage.getItem("role_id");
  useEffect(() => {
    fetchRecords();
  }, [flag]);

  const fetchRecords = (
    page = 1,
    pageSize = 10,
    companyName = "",
    nik = ""
  ) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/users?page=${page}&per_page=${pageSize}&company_name=${companyName}&nik=${nik}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListData(response.data.data);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const getLocationList = (company_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/location?company_id=${company_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let newLocationList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newLocationList.push({
            value: element.id,
            label: element.name,
          });
        }
        setLocationList(newLocationList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    if (actionForm === "create") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/users`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/users/${DetailId}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setIsLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onChangeRole = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearchRole = (value) => {
    console.log("search:", value);
  };

  const filterOptionRole = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeCompany = (value) => {
    console.log(`selected ${value}`);
    getLocationList(value);
  };

  const onSearchCompany = (value) => {
    console.log("search:", value);
  };

  const filterOptionCompany = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangePosition = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearchPosition = (value) => {
    console.log("search:", value);
  };

  const filterOptionPosition = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterOptionGender = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Data Karyawan</p>
        <div>
          <Button
            type="primary"
            onClick={() => {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/users/export?company_name=${companyFilter}&nik=${nikFilter}`,
                  {
                    responseType: "blob",
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  const href = URL.createObjectURL(response.data);
                  const link = document.createElement("a");
                  link.href = href;
                  link.setAttribute("download", "karyawan.xlsx"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
                });
            }}
            style={{ marginRight: "10px" }}
          >
            Export
          </Button>
          <Button
            type="primary"
            onClick={() => {
              showModal("create");
              setActionForm("create");
            }}
          >
            Tambah
          </Button>
        </div>
      </div>
      <div className="mx-16 mt-16">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
        >
          {roleId === "1" && (
            <Form.Item
              name="company_name"
              label="Nama Perusahaan"
              style={{ width: "50%" }}
              onChange={(e) => {
                setCompanyFilter(e.target.value);
              }}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="nik"
            label="NIK"
            style={{ width: "50%" }}
            onChange={(e) => {
              setNikFilter(e.target.value);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "left" }}
              onClick={() => {
                fetchRecords(1, 10, companyFilter, nikFilter);
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>

        <Table
          columns={columns}
          dataSource={ListData}
          pagination={{
            total: totalListData,
            onChange: (page, pageSize) => {
              fetchRecords(page, pageSize, companyFilter, nikFilter);
            },
          }}
        />
      </div>
      <Modal
        title="Form Karyawan"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          isLoading ? <Spin key="loading" /> : null,
          !isLoading ? (
            <Button form="formEmployeeList" key="submit" htmlType="submit">
              Submit
            </Button>
          ) : null,
        ]}
      >
        <Form
          form={form}
          name="formEmployeeList"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Nama Karyawan"
            name="name"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Email tidak valid!",
              },
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="No Telp"
            name="phone"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Alamat"
            name="address"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Jabatan"
            name="position_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu jabatan"
              optionFilterProp="children"
              onChange={onChangePosition}
              onSearch={onSearchPosition}
              filterOption={filterOptionPosition}
              options={listPosition}
            />
          </Form.Item>

          <Form.Item
            label="Jenis Kelamin"
            name="gender"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu jenis kelamin"
              optionFilterProp="children"
              filterOption={filterOptionGender}
              options={[
                { value: "Laki-Laki", label: "Laki-Laki" },
                { value: "Perempuan", label: "Perempuan" },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu role"
              optionFilterProp="children"
              onChange={onChangeRole}
              onSearch={onSearchRole}
              filterOption={filterOptionRole}
              options={listRole}
            />
          </Form.Item>

          <Form.Item
            label="Perusahaan"
            name="company_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu perusahaan"
              optionFilterProp="children"
              onChange={onChangeCompany}
              onSearch={onSearchCompany}
              filterOption={filterOptionCompany}
              options={listCompany}
            />
          </Form.Item>

          <Form.Item
            label="Lokasi"
            name="location_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu lokasi"
              optionFilterProp="children"
              filterOption={filterOptionCompany}
              options={locationList}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EmployeeList;

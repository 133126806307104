// import logo from "./logo.svg";
import "./App.css";
import React from "react";
// import "bootstrap/dist/css/bootstrap.css";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import EmployeeList from "./pages/employee/EmployeeList";
import LocationList from "./pages/location/LocationList";
import CompanyList from "./pages/company/CompanyList";
import ChangePassword from "./pages/change-password/ChangePassword";
import PlottingList from "./pages/plotting/PlottingList";
import SubPlottingList from "./pages/sub-plotting/SubPlottingList";
import ScheduleList from "./pages/schedule/ScheduleList";
import ReportAbsentList from "./pages/report-absent/ReportAbsentList";
import Checklist from "./pages/checklist/Checklist";
import ReportChecklist from "./pages/report-checklist/ReportChecklist";
import MasterAssetList from "./pages/master-asset/MasterAssetList";
import ReportAsset from "./pages/report-asset/ReportAsset";
import MaterialFault from "./pages/material-fault/MaterialFault";

function App() {
  const splitUrl = window.location.href.split("/");
  return (
    <Router>
      <div className="App">
        {splitUrl[3] !== "login" ? (
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/company" element={<CompanyList />} />
              <Route path="/employee" element={<EmployeeList />} />
              <Route path="/location" element={<LocationList />} />
              <Route
                path="/location/add-plotting/:id"
                element={<PlottingList />}
              />
              <Route
                path="/location/add-sub-plotting/:id"
                element={<SubPlottingList />}
              />
              <Route path="/schedule" element={<ScheduleList />} />
              <Route path="/master-asset" element={<MasterAssetList />} />
              <Route path="/checklist" element={<Checklist />} />
              <Route path="/report-absent" element={<ReportAbsentList />} />
              <Route path="/report-checklist" element={<ReportChecklist />} />
              <Route path="/report-asset" element={<ReportAsset />} />
              <Route path="/material-fault" element={<MaterialFault />} />
            </Routes>
          </Layout>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;

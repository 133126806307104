import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

const Checklist = () => {
  const [form] = Form.useForm();
  const [ListData, setListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(0);
  const [actionForm, setActionForm] = useState("");
  const [DetailId, setDetailId] = useState(0);
  const [listLocation, setListLocation] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [plotList, setPlotList] = useState([]);
  const [subPlotList, setSubPlotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");

  const showModal = (action = "create") => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/location`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newListLocation = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newListLocation.push({
            value: element.id,
            label: `${element.name}`,
          });
        }
        setListLocation(newListLocation);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });

    if (action === "create") {
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "NFC",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Lokasi",
      dataIndex: "location.name",
      key: "location.name",
      render: (_, record) => {
        return record.location.name;
      },
    },
    {
      title: "Plot",
      dataIndex: "plot.name",
      key: "plot.name",
      render: (_, record) => {
        return record.plot.name;
      },
    },
    {
      title: "Sub Plot",
      dataIndex: "sub_plot.name",
      key: "sub_plot.name",
      render: (_, record) => {
        return record.sub_plot.name;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setActionForm("update");
              setDetailId(record.id);
              axios
                .get(`${process.env.REACT_APP_API_URL}/nfc/${record.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  showModal();
                  let detail = response.data.data;
                  let newItem = [];
                  for (let i = 0; i < detail.item.length; i++) {
                    const item = detail.item[i];
                    newItem.push(item.name);
                  }
                  detail.items = newItem;
                  form.setFieldsValue(detail);
                  getPlotByLocation(detail.location_id);
                  getSubPlotByPlot(detail.plotting_id);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    window.location.href = "/login";
                  }
                });
            }}
          >
            Ubah
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/nfc/${record.id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      setFlag((prev) => prev + 1);
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

  const token = window.localStorage.getItem("token");
  useEffect(() => {
    fetchRecords(1, 10);
  }, [flag]);

  const fetchRecords = (page, pageSize, locationFilter = "") => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/nfc?page=${page}&per_page=${pageSize}&location_name=${locationFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListData(response.data.data);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    if (actionForm === "create") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/nfc`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/nfc/${DetailId}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setIsLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onChangeLocation = (value) => {
    getPlotByLocation(value);
  };

  const getPlotByLocation = (locationId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/plotting?location_id=${locationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let newPlotList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newPlotList.push({
            value: element.id,
            label: element.name,
          });
        }
        setPlotList(newPlotList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const filterOptionPlot = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangePlot = (value) => {
    getSubPlotByPlot(value);
  };

  const getSubPlotByPlot = (plotId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subplotting?plot_id=${plotId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newSubPlotList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newSubPlotList.push({
            value: element.id,
            label: element.name,
          });
        }
        setSubPlotList(newSubPlotList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const filterOptionSubPlot = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearchLocation = (value) => {
    console.log("search:", value);
  };

  const filterOptionLocation = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Data Checklist</p>
        <Button
          type="primary"
          onClick={() => {
            showModal("create");
            setActionForm("create");
          }}
        >
          Tambah
        </Button>
      </div>
      <div className="mx-16 mt-16">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="location_name"
            label="Nama Lokasi"
            style={{ width: "50%" }}
            onChange={(e) => {
              setLocationFilter(e.target.value);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "left" }}
              onClick={() => {
                fetchRecords(1, 10, locationFilter);
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={ListData}
          pagination={{
            total: totalListData,
            onChange: (page, pageSize) => {
              fetchRecords(page, pageSize, locationFilter);
            },
          }}
        />
      </div>
      <Modal
        title="Form Checklist"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          isLoading ? <Spin key="loading" /> : null,
          !isLoading ? (
            <Button form="formChecklist" key="submit" htmlType="submit">
              Submit
            </Button>
          ) : null,
        ]}
      >
        <Form
          form={form}
          name="formChecklist"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="NFC UID"
            name="uid"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Lokasi"
            name="location_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu jabatan"
              optionFilterProp="children"
              onChange={onChangeLocation}
              onSearch={onSearchLocation}
              filterOption={filterOptionLocation}
              options={listLocation}
            />
          </Form.Item>

          <Form.Item
            label="Plot"
            name="plotting_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu plot"
              optionFilterProp="children"
              onChange={onChangePlot}
              filterOption={filterOptionPlot}
              options={plotList}
            />
          </Form.Item>

          <Form.Item
            label="Sub Plot"
            name="sub_plotting_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu sub plot"
              optionFilterProp="children"
              filterOption={filterOptionSubPlot}
              options={subPlotList}
            />
          </Form.Item>

          <Form.List
            name="items"
            rules={[
              {
                validator: async (_, items) => {
                  if (!items || items.length < 1) {
                    return Promise.reject(new Error("Tambah minimal 1 item"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Item" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Harap isi nama item atau hapus field ini",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="nama item"
                        style={{
                          width: "60%",
                        }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: "60%",
                    }}
                    icon={<PlusOutlined />}
                  >
                    Tambah Item
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default Checklist;

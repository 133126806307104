import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Alert, Space } from "antd";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const LoginForm = () => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/login`, values)
      .then((response) => {
        const decodeToken = jwtDecode(response.data.token);
        const user = response.data.user;
        window.localStorage.setItem("userId", user.id);
        window.localStorage.setItem("userName", decodeToken.name);
        window.localStorage.setItem("roleId", user.role_id);
        window.localStorage.setItem("roleName", user.role_name);
        window.localStorage.setItem("positionId", user.position_id);
        window.localStorage.setItem("positionName", user.position_name);
        window.localStorage.setItem("locationId", user.location_id);
        window.localStorage.setItem("locationName", user?.location_name);
        window.localStorage.setItem("companyId", user.company_id);
        window.localStorage.setItem("companyName", user.company_name);
        window.localStorage.setItem("token", response.data.token);

        if (parseInt(decodeToken.is_first_login) === 1) {
          window.location.href = "/change-password";
        } else {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage(error.response.data.message);
      });
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <Form
        name="normal_login"
        className="login-form p-8 rounded-md"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        style={{ width: "500px", border: "2px solid rgb(229 229 229)" }}
      >
        <img
          src={"/amartha-app-logo.png"}
          className="logo"
          style={{ margin: "auto", paddingBottom: "15px" }}
        />
        {showError && (
          <Space
            direction="vertical"
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <Alert message={errorMessage} type="error" showIcon closable />
          </Space>
        )}
        <Form.Item
          name="nik"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default LoginForm;

import React, { useEffect, useState } from "react";
import { Table, DatePicker } from "antd";
import axios from "axios";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import TotalSummary from "./components/TotalSummary";
const { RangePicker } = DatePicker;

const EmployeeList = () => {
  const [ListData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState(0);
  const [listDataChart, setListDataChart] = useState([{ total: 0, month: "" }]);
  const columns = [
    {
      title: "No",
      render: (_, re, idx) => {
        return idx + 1;
      },
    },
    {
      title: "Area",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Done",
      dataIndex: "task_done",
      key: "task_done",
    },
    {
      title: "Total",
      dataIndex: "total_task",
      key: "total_task",
    },
    {
      title: "SPV",
      dataIndex: "supervisor",
      key: "supervisor",
    },
  ];

  const token = window.localStorage.getItem("token");

  const fetchApprovedChecklistChart = (startDate = "", endDate = "") => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/dashboard/get-approved-checklist-chart?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length) {
          setListDataChart(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  useEffect(() => {
    fetchTableDashboard(1, 10);
    fetchApprovedChecklistChart();
  }, []);

  const fetchTableDashboard = (page, pageSize) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/dashboard/get-dashboard-table?page=${page}&per_page=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListData(response.data.data);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const valueFormatter = (value) => `${value}`;

  const chartSetting = {
    yAxis: [
      {
        label: "checklist",
      },
    ],
    series: [{ dataKey: "total", label: "Approved Checklist", valueFormatter }],
    height: 300,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };

  const handleOnChangeRangeDate = (value) => {
    if (value[0] && value[1]) {
      const [startDate, endDate] = value;
      fetchApprovedChecklistChart(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
    }
  };

  return (
    <>
      <div className="grid grid-rows-3 grid-flow-col gap-4">
        <div className="row-span-3 col-span-12 shadow-lg">
          <div id="add-user">
            <div className="titile-section mt-2 mx-8 flex justify-between items-center">
              <p className="text-2xl ml-4">Data Checklist</p>
            </div>
            <div className="mx-16 mt-10">
              <Table
                columns={columns}
                dataSource={ListData}
                pagination={{
                  total: totalListData,
                  onChange: (page, pageSize) => {
                    fetchTableDashboard(page, pageSize);
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-span-2 shadow-lg">
          <TotalSummary />
        </div>
        <div className="row-span-2 col-span-2 shadow-lg">
          <div id="add-user">
            <div className="titile-section mt-2 mx-8 flex justify-between items-center">
              <p className="text-2xl ml-4">Approved Checklists</p>
              <RangePicker
                onChange={handleOnChangeRangeDate}
                format="YYYY-MM-DD"
              />
            </div>
            <div className="mx-16 mt-10">
              <BarChart
                dataset={listDataChart}
                xAxis={[{ scaleType: "band", dataKey: "month" }]}
                {...chartSetting}
                width={500}
                height={300}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeList;

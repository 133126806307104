import React, { useEffect, useState } from "react";
import { Button, Modal, message, Table, DatePicker, Form } from "antd";
import axios from "axios";
import * as moment from "moment-timezone";
const { RangePicker } = DatePicker;

const ReportAsset = () => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [ListData, setListData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [totalListData, setTotalListData] = useState(0);
  const columns = [
    {
      title: "Asset SN",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "NFC UID",
      dataIndex: "nfc_uid",
      key: "nfc_uid",
    },
    {
      title: "Nama Asset",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Lokasi",
      dataIndex: "location_name",
      key: "location_name",
      render: (_, record) => {
        return record.location_name;
      },
    },
    {
      title: "Plotting",
      dataIndex: "plot_name",
      key: "plot_name",
      render: (_, record) => {
        return record.plot_name;
      },
    },
    {
      title: "Tanggal",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        if (record.date) {
          return moment.utc(record.date).format("YYYY-MM-DD HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "condition",
      key: "condition",
    },
  ];

  const token = window.localStorage.getItem("token");
  useEffect(() => {
    fetchRecords();
  }, []);

  const fetchRecords = (
    page = 1,
    pageSize = 10,
    startDate = "",
    endDate = ""
  ) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/asset/get-report?page=${page}&per_page=${pageSize}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const rows = response.data.data;
        let newData = [];
        if (rows.length > 0) {
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            newData.push({
              key: row.id,
              serial_number: row?.asset?.serial_number,
              nfc_uid: row?.asset?.nfc_uid,
              name: row?.asset?.name,
              location_name: row?.asset?.location?.name,
              plot_name: row?.asset?.plot?.name,
              date: row.date,
              condition: row.condition,
            });
          }
        }
        setListData(newData);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const handleOnChangeRangeDate = (value) => {
    if (value[0] && value[1]) {
      setFilterStartDate(value[0].format("YYYY-MM-DD"));
      setFilterEndDate(value[1].format("YYYY-MM-DD"));
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record) => ({
      key: record.id,
    }),
  };

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Report Asset</p>
        <Button
          type="primary"
          onClick={() => {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/asset/export?start_date=${filterStartDate}&end_date=${filterEndDate}`,
                {
                  responseType: "blob",
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "Report Asset.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              });
          }}
        >
          Export
        </Button>
      </div>
      <div className="mx-16 mt-16">
        <Form name="validateOnly" layout="vertical" autoComplete="off">
          <Form.Item name="date_range" label="Tanggal" style={{ width: "50%" }}>
            <RangePicker
              onChange={handleOnChangeRangeDate}
              format="YYYY-MM-DD"
              style={{ float: "left" }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "left" }}
              onClick={() => {
                fetchRecords(1, 10, filterStartDate, filterEndDate);
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
        {selectedRow.length > 0 && (
          <Button
            danger
            type="primary"
            style={{ float: "left", marginBottom: 15 }}
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/asset/delete-report`,
                      {
                        assets: selectedRow,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      fetchRecords();
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Delete
          </Button>
        )}
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={ListData}
          pagination={{
            total: totalListData,
            onChange: (page, pageSize) => {
              fetchRecords(page, pageSize, filterStartDate, filterEndDate);
            },
          }}
        />
      </div>
    </div>
  );
};

export default ReportAsset;

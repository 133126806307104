import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TeamOutlined,
  EnvironmentOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

const TotalSummary = () => {
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [totalLocation, setTotalLocation] = useState(0);
  const [totalTaskDone, setTotalTaskDone] = useState(0);
  const [totalTask, setTotalTask] = useState(0);
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/get-total-employee`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTotalEmployee(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/get-total-location`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTotalLocation(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/get-dashboard-task`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTotalTaskDone(response.data.data.task_done);
        setTotalTask(response.data.data.total_task);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  }, []);
  return (
    <>
      <div className="grid grid-rows-3 grid-flow-col gap-3">
        <div className="flex items-center">
          <div className="flex-shrink-0 ml-2">
            <TeamOutlined />
          </div>
          <div className="flex-1 min-w-0 ms-4">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-black text-left">
              {totalEmployee}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400 text-left">
              Total Employees
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-shrink-0 ml-2">
            <TeamOutlined />
          </div>
          <div className="flex-1 min-w-0 ms-4">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-black text-left">
              {totalEmployee}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400 text-left">
              Employee Present
            </p>
          </div>
        </div>
        <div className="flex items-center mb-3">
          <div className="flex-shrink-0 ml-2">
            <EnvironmentOutlined />
          </div>
          <div className="flex-1 min-w-0 ms-4">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-black text-left">
              {totalLocation}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400 text-left">
              Total Area
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <CheckCircleOutlined />
          </div>
          <div className="flex-1 min-w-0 ms-4">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-black text-left">
              {totalTaskDone === null ? 0 : totalTaskDone}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400 text-left">
              Tasks Done
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <FileDoneOutlined />
          </div>
          <div className="flex-1 min-w-0 ms-4">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-black text-left">
              {totalTask}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400 text-left">
              Total Tasks
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalSummary;

import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Table, Form, Input, message, Spin } from "antd";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const PlottingList = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const [ListData, setListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(0);
  const [actionForm, setActionForm] = useState("");
  const [DetailId, setDetailId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const showModal = (action = "create") => {
    if (action === "create") {
      form.resetFields();
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Nama Plot",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setActionForm("update");
              setDetailId(record.id);
              axios
                .get(`${process.env.REACT_APP_API_URL}/plotting/${record.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  showModal();
                  form.setFieldsValue(response.data.data);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    window.location.href = "/login";
                  }
                });
            }}
          >
            Ubah
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/plotting/${record.id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      setFlag((prev) => prev + 1);
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Hapus
          </Button>
          <Link
            to={`${process.env.REACT_APP_URL}/location/add-sub-plotting/${record.id}`}
          >
            <Button type="primary">Add Sub Plotting</Button>
          </Link>
        </Space>
      ),
    },
  ];

  const token = window.localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/plotting?location_id=${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListData(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  }, [flag]);
  const onFinish = (values) => {
    setIsLoading(true);
    values["location_id"] = params.id;
    if (actionForm === "create") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/plotting`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/plotting/${DetailId}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.message,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setIsLoading(false);
    console.log("Failed:", errorInfo);
  };

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Data Plotting</p>
        <Button
          type="primary"
          onClick={() => {
            showModal("create");
            setActionForm("create");
          }}
        >
          Tambah
        </Button>
      </div>
      <div className="mx-16 mt-16">
        <Table columns={columns} dataSource={ListData} />
      </div>
      <Modal
        title="Form Perusahaan"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          isLoading ? <Spin key="loading" /> : null,
          !isLoading ? (
            <Button form="formPlottingList" key="submit" htmlType="submit">
              Submit
            </Button>
          ) : null,
        ]}
      >
        <Form
          form={form}
          name="formPlottingList"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Nama Plot"
            name="name"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PlottingList;

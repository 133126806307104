import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";

const ChangePassword = () => {
  const [form] = Form.useForm();

  const token = window.localStorage.getItem("token");

  const onFinish = (values) => {
    console.log("Success:", values);
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/change-password`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        message.success({
          content: response.data.message,
        });
        form.resetFields();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          message.error({
            content: error.response.data.errors[0].msg,
          });
        } else {
          message.error({
            content: error.message,
          });
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="dependencies"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{
        maxWidth: 600,
      }}
      layout="vertical"
    >
      <Form.Item
        label="New Password"
        name="password"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      {/* Field */}
      <Form.Item
        label="Confirm New Password"
        name="password2"
        dependencies={["password"]}
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new password that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;

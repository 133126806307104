import {
  HomeOutlined,
  EnvironmentOutlined,
  UserOutlined,
  BankOutlined,
  FormOutlined,
  FileExcelOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./sidebar.scss";

const CustomeSidebar = ({ toggleCollapse }) => {
  const [menuSidebar, setMenuSidebar] = useState();
  const [currentRole, setCurrentRole] = useState();
  const location = useLocation();

  const menus = [
    {
      label: "Beranda",
      icon: <HomeOutlined />,
      link: "/dashboard",
      roles: [1, 2, 6],
    },
    {
      label: "Perusahaan",
      icon: <BankOutlined />,
      link: "/company",
      roles: [1],
    },
    {
      label: "Karyawan",
      icon: <UserOutlined />,
      link: "/employee",
      roles: [1, 2],
    },
    {
      label: "Lokasi",
      icon: <EnvironmentOutlined />,
      link: "/location",
      roles: [1, 2],
    },
    {
      label: "Jadwal",
      icon: <FormOutlined />,
      link: "/schedule",
      roles: [1, 2],
    },
    {
      label: "Checklist",
      icon: <CheckSquareOutlined />,
      link: "/checklist",
      roles: [1, 2],
    },
    {
      label: "Absensi Report",
      icon: <FileExcelOutlined />,
      link: "/report-absent",
      roles: [1, 2, 6],
    },
    {
      label: "Checklist Report",
      icon: <FileExcelOutlined />,
      link: "/report-checklist",
      roles: [1, 2, 6],
    },
    {
      label: "Asset Report",
      icon: <FileExcelOutlined />,
      link: "/report-asset",
      roles: [1, 2, 6],
    },
    {
      label: "Material Fault",
      icon: <FileExcelOutlined />,
      link: "/material-fault",
      roles: [1, 2, 6],
    },
  ];

  useEffect(() => {
    const roleId = window.localStorage.getItem("roleId");
    if (!roleId) {
      window.location.href = "/login";
    }
    setCurrentRole(roleId);
    setMenuSidebar(menus);
  }, []);

  const wrapperClasses = classNames(
    "h-screen px-4 pt-4 pb-4 bg-light flex justify-between flex-col fixed sidebar-wp",
    {
      ["w-60"]: !toggleCollapse,
      ["w-20"]: toggleCollapse,
    }
  );

  const getNavItemClasses = (menu) => {
    return classNames("flex items-center menu-wrapper", {
      ["active"]: location.pathname.includes(menu.link),
    });
  };

  return (
    <div
      className={wrapperClasses}
      style={{
        transition: "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s",
        border: "2px solid #f3f3f3",
        zIndex: "9",
        backgroundColor: "#fff",
      }}
    >
      <div className="flex flex-col">
        <img src={"/amartha-app-logo.png"} className="logo" />

        <div
          className="flex flex-col items-start mt-10"
          style={{ color: "#000" }}
        >
          {menuSidebar?.map(({ icon, ...menu }) => {
            const classes = getNavItemClasses(menu);
            return (
              <>
                {menu.roles.includes(parseInt(currentRole)) && (
                  <div className={classes}>
                    <Link to={menu.link}>
                      <div className="flex py-4 px-3 items-center w-full h-full">
                        <div style={{ width: "2.5rem" }}>{icon}</div>
                        {!toggleCollapse && (
                          <span
                            className={classNames(
                              "text-md font-medium text-text-light"
                            )}
                          >
                            {menu.label}{" "}
                            {menu.roles.includes(parseInt(currentRole))}
                          </span>
                        )}
                      </div>
                    </Link>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomeSidebar;

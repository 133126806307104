import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  Form,
  Input,
  message,
  Spin,
  DatePicker,
  Select,
} from "antd";
import axios from "axios";
import * as moment from "moment-timezone";

const MasterAssetList = () => {
  const [form] = Form.useForm();
  const [ListData, setListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(0);
  const [actionForm, setActionForm] = useState("");
  const [DetailId, setDetailId] = useState(0);
  const [totalListData, setTotalListData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [plotList, setPlotList] = useState([]);
  const [subPlotList, setSubPlotList] = useState([]);

  const showModal = (action = "create") => {
    getLocationList();
    if (action === "create") {
      form.resetFields();
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Asset SN",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "NFC UID",
      dataIndex: "nfc_uid",
      key: "nfc_uid",
    },
    {
      title: "Nama Asset",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Lokasi",
      dataIndex: "location.name",
      key: "location.name",
      render: (_, record) => {
        return record?.location?.name;
      },
    },
    {
      title: "Plotting",
      dataIndex: "plot.name",
      key: "plot.name",
      render: (_, record) => {
        return record?.plot?.name;
      },
    },
    {
      title: "Sub Plot",
      dataIndex: "sub_plot.name",
      key: "sub_plot.name",
      render: (_, record) => {
        return record?.sub_plot?.name;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setActionForm("update");
              setDetailId(record.id);
              axios
                .get(`${process.env.REACT_APP_API_URL}/asset/${record.id}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  showModal();
                  form.setFieldsValue(response.data.data);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    window.location.href = "/login";
                  }
                });
            }}
          >
            Ubah
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.confirm({
                title: "Confirm",
                content: "Are you sure want to delete this data?",
                onOk: () => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/asset/${record.id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      setFlag((prev) => prev + 1);
                      message.success({
                        content: response.data.message,
                      });
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        window.location.href = "/login";
                      } else {
                        message.error({
                          content: error.message,
                        });
                      }
                    });
                },
              });
            }}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const token = window.localStorage.getItem("token");
  useEffect(() => {
    fetchRecords(1, 10);
  }, [flag]);

  const fetchRecords = (page, pageSize) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/asset?page=${page}&per_page=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListData(response.data.data);
        setTotalListData(response.data.totalData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    if (actionForm === "create") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/asset`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.response.data.message,
            });
          }
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/asset/${DetailId}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setFlag((prev) => prev + 1);
          message.success({
            content: response.data.message,
          });
          handleOk();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            message.error({
              content: error.response.data.message,
            });
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setIsLoading(false);
    console.log("Failed:", errorInfo);
  };

  const getLocationList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/location`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newLocationList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newLocationList.push({
            value: element.id,
            label: element.name,
          });
        }
        setLocationList(newLocationList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onChangeLocation = (value) => {
    getPlotByLocation(value);
  };

  const getPlotByLocation = (locationId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/plotting?location_id=${locationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let newPlotList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newPlotList.push({
            value: element.id,
            label: element.name,
          });
        }
        setPlotList(newPlotList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const onChangePlot = (value) => {
    getSubPlotByPlot(value);
  };

  const getSubPlotByPlot = (plotId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subplotting?plot_id=${plotId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newSubPlotList = [];
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          newSubPlotList.push({
            value: element.id,
            label: element.name,
          });
        }
        setSubPlotList(newSubPlotList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div id="add-user">
      <div className="titile-section mt-2 mx-8 flex justify-between items-center">
        <p className="text-2xl ml-4">Data Master Asset</p>
        <Button
          type="primary"
          onClick={() => {
            showModal("create");
            setActionForm("create");
          }}
        >
          Tambah
        </Button>
      </div>
      <div className="mx-16 mt-16">
        <Table
          columns={columns}
          dataSource={ListData}
          pagination={{
            total: totalListData,
            onChange: (page, pageSize) => {
              fetchRecords(page, pageSize);
            },
          }}
        />
      </div>
      <Modal
        title="Form Master Asset"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          isLoading ? <Spin key="loading" /> : null,
          !isLoading ? (
            <Button form="formMasterAssetList" key="submit" htmlType="submit">
              Submit
            </Button>
          ) : null,
        ]}
      >
        <Form
          form={form}
          name="formMasterAssetList"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="NFC UID"
            name="nfc_uid"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Asset Serial Number"
            name="serial_number"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nama Asset"
            name="name"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Lokasi"
            name="location_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu lokasi"
              optionFilterProp="children"
              onChange={onChangeLocation}
              filterOption={filterOption}
              options={locationList}
            />
          </Form.Item>

          <Form.Item
            label="Plot"
            name="plot_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu plot"
              optionFilterProp="children"
              onChange={onChangePlot}
              filterOption={filterOption}
              options={plotList}
            />
          </Form.Item>

          <Form.Item
            label="Sub Plot"
            name="sub_plot_id"
            rules={[
              {
                required: true,
                message: "Data ini harus diisi!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Pilih satu plot"
              optionFilterProp="children"
              filterOption={filterOption}
              options={subPlotList}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MasterAssetList;

import React, { ReactNode, useState } from "react";
import { Footer } from "./footer";
import Header from "./header";
import CustomeSidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [toggleCollapse, setToggleCollapse] = useState(false);

  const handleSidebarToggle = () => {
    setToggleCollapse(!toggleCollapse);
  };

  return (
    <div className="h-screen flex flex-row justify-start">
      <CustomeSidebar toggleCollapse={toggleCollapse} />
      <div
        className={`${toggleCollapse ? "ml-24" : "ml-60"}`}
        style={{ width: "100%" }}
      >
        <Header handleSidebarToggle={handleSidebarToggle} />
        <div
          className="p-4 "
          style={{ width: "100%", minHeight: "calc(100vh - 105px)" }}
        >
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
